import React, { useContext } from "react";

import LogInForm from "./../components/login-form";
import UserContext from "../context/user-context";
import { Navigate } from "react-router-dom";
import config from "../config";

const LoginPage = () => {
  const userContext = useContext(UserContext);
  return !userContext.user.isLoggedIn ? (
    <div style={{ maxWidth: "600px" }}>
      <h1>Log In</h1>
      <LogInForm />
    </div>
  ) : (
    <Navigate to={config.server.siteRoot} />
  );
};

export default LoginPage;
