import React from "react";

import { useRecoilValue } from "recoil";
import searchFiltersAtom from "../recoil/searchFiltersAtom";

const TotalResults = () => {
  const searchFilters = useRecoilValue(searchFiltersAtom);

  return (
    <>
      {searchFilters.totalResults && (
        <div>Total Results: {searchFilters.totalResults}</div>
      )}
    </>
  );
};

export default TotalResults;
