import React, { createContext, useState } from "react";
import { authAPI, rolesAPI } from "../services/api";
import { createBrowserHistory } from "history";
import axios from "axios";
import config from "../config";

import jwt_decode from "jwt-decode";
import { connectLogger } from "./../utilities/utilities";
import {
  clearSessionStorage,
  getLocalToken,
  getToUrl,
  getUser,
  rolesToString,
  setUserSessionStorage,
  storeData,
  tokenExists,
} from "./../utilities/authentication";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const UserContext = createContext();
UserContext.displayName = "UserContext";
export default UserContext;

/**
 * The GUEST_USER object defines the initial state of the user that is passed down to React components from the UserProvider.
 */
const GUEST_USER = {
  user: {},
  isLoggedIn: false, // (boolean) Is the user logged in or not?
  displayName: "", // User name to show in the navbar message after login
};

// Variable reference that will receive the setInterval() instance for the token refresh so we can clear the interval.
let refreshTokenInterval = null;
// Variable reference that will receive the setInterval() instance for monitoring for user activity so we can clear the interval.
let activityMonitor = null;

export const UserProvider = (props) => {
  const [user, setUserState] = useState(GUEST_USER);
  let [userError, setUserError] = useState({
    error: false,
    title: "",
    message: "",
  });
  const [userActivityState, setUserActivityState] = useState("active");

  /**
   * Function that monitors user activity and updates the userActivityState of user context.
   * The function is instantiated after token validation.
   */
  function activityWatcher() {
    //The number of seconds that have passed since the user was active.
    let secondsSinceLastActivity = 0;

    // Setup the setInterval method to run every second.
    activityMonitor = setInterval(function () {
      secondsSinceLastActivity++;
      // If the user has been inactive or idle for longer than the inactivity timeout.
      if (secondsSinceLastActivity > config.inactivityTimeout) {
        // Update the user activity state to inactive.
        if (userActivityState === "active") {
          setUserActivityState("inactive");
        }
      } else {
        if (userActivityState) setUserActivityState("active");
      }
    }, 1000);

    // The function that will be called whenever user activity is detected.
    function resetInactivity() {
      // Reset the secondsSinceLastActivity back to 0.
      secondsSinceLastActivity = 0;
    }

    // An array of DOM events that indicate user activity
    var activityEvents = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
    ];

    // Add event listeners to call the resetInactivity to reset the counter when activity is detected.
    activityEvents.forEach(function (eventName) {
      document.addEventListener(eventName, resetInactivity, true);
    });
  }

  // Wrapping the set state functions used by the context with a simple logging function that will log the context values to the console
  // when the setter functions are called to set state and the application is in debugMode.
  const setUser = connectLogger(setUserState, "UserContext::user");
  setUserError = connectLogger(setUserError, "UserContext::userError");
  /**
   * Login function used by the application to authenticate users.
   * @param {String} username username of the user logging in.
   * @param {*} password username of the user logging in.
   */
  const handleLogin = (username, password) => {
    let creds = { name: username, password: password };

    // Clear out any existing errors
    setUserError({
      error: false,
      title: "",
      message: "",
    });

    axios
      .post(config.services.loginService, creds)
      .then((res) => {
        // Handle authentication service response.
        if (res.data.jwt) {
          storeData("jwt", res.data.jwt);
          let user = {
            user: res.data.user,
            name: res.data.name,
            displayName: res.data.name,
            mail: res.data.mail,
          };
          setUserSessionStorage(user);
          setUserError({
            error: false,
            title: "",
            message: "",
          });
          setUser({
            isLoggedIn: true,
            displayName: user.displayName,
            roles: [],
          });
          if (config.useRoles === true) {
            // Get user roles from the rolesAPI
            rolesAPI
              .get(config.services.rolesService)
              .then((res) => {
                if (Array.isArray(res.data.roles)) {
                  const userObj = getUser();
                  const strRoles = rolesToString(res.data.roles);
                  setUserSessionStorage({
                    ...userObj,
                    // Append roles to the User object in session storage.
                    roles: strRoles,
                  });

                  setUser({
                    isLoggedIn: true,
                    displayName: user.displayName,
                    roles: strRoles,
                  });
                }
              })
              .catch((err) => {
                console.log(
                  `Failed to retrieve roles from ${config.services.rolesService}`,
                  err
                );
              });
          }
        } else {
          // LOGIN FAILED. set error state.
          const message =
            res.config && res.config.url && res.config.method
              ? `${res.message}. ${res.config.method.toUpperCase()} ${
                  res.config.url
                }`
              : res.message;
          setUserError({
            error: true,
            title: res.error,
            message: message,
          });
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          let title = error.response?.data?.result || "Invalid login";
          let message =
            error.response?.data?.status === "error" &&
            error.response?.data?.value
              ? error.response.data.value
              : "The user name or password were incorrect.";
          setUserError({
            error: true,
            title: title,
            message: message,
          });
        }
        if (
          error &&
          error.response &&
          (error.response.status === 400 || error.response.status === 403)
        ) {
          let title = error.response?.data?.result
            ? error.response.data.result
            : "Invalid permission";
          let message =
            error.response?.data?.status === "error" &&
            error.response?.data?.value
              ? error.response.data.value
              : "Your account does not have permission to access this application.";
          setUserError({
            error: true,
            title: title,
            message: message,
          });
        } else {
          let message = "";
          if (
            error &&
            error.config &&
            error.config.url &&
            error.config.method
          ) {
            message = `${error.message}. ${error.config.method.toUpperCase()} ${
              error.config.url
            }`;
          } else if (error && error.message) {
            message = error.message;
          } else {
            message = error;
          }

          setUserError({
            error: true,
            title: `Error ${error.response?.status}`,
            message: message,
          });
        }
      });
  };

  /**
   * Logout function to log a user out of the application and clear session storage.
   */
  const logOut = () => {
    sessionStorage.clear();
    clearTimeout(refreshTokenInterval);
    clearTimeout(activityMonitor);
    setUser(GUEST_USER);
    setUserActivityState("active");
  };

  /**
   * Performs a validation of the user token found in the browser's session storage by sending a validation request to an authentication endpoint with the JWT token.
   * If they JWT is successfully validated, the user is set to logged in and directed to their original target URL.
   */
  const validateToken = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const history = createBrowserHistory();
    if (!user.isLoggedIn && tokenExists()) {
      const checkToken = async () => {
        const options = {
          method: "HEAD",
          url: config.services.tokenValidationService,
          cancelToken: source.token,
        };
        await authAPI(options)
          .then((res) => {
            if (res.status && (res.status === 200 || res.status === 204)) {
              // update the user login status and populate the displayName from the localSession token.
              setUser({
                isLoggedIn: true,
                displayName: JSON.parse(getLocalToken(config.userStorageKey))
                  .name,
              });

              // If the token is older, let's grab a fresh token.
              if (getLocalToken("jwt")) {
                const currentTokenExpiry = jwt_decode(
                  getLocalToken("jwt")
                ).expiry;

                const tokenExpirationDate = dayjs(currentTokenExpiry);
                const currentDate = dayjs(Date.now());
                const dateDiff = tokenExpirationDate.diff(
                  currentDate,
                  "minutes"
                );

                // If the token expiration time is within the next refresh interval time plus 10 minutes,
                // go ahead and get a fresh token now.
                if (dateDiff < config.tokenRefreshInterval / 60 + 10) {
                  getRefreshToken();
                }
              }
              // Grab the URL the user is trying to reach before performing validating the authentication token.
              // This will be used to redirect the user to that page if the token is successfully validated.
              history.push(getToUrl());
              return res;
            } else {
              history.push(config.server.siteRoot);
              clearSessionStorage();
              setUserError({
                error: true,
                title: "Unauthorized",
                message:
                  "You are not authorized to access this page. Please login.",
              });
            }
          })
          .catch((error) => {
            clearSessionStorage();
            history.push(config.server.siteRoot);
            setUserError({
              error: true,
              title: "Error",
              message: `A problem occurred while validating the authentication token. ${error}`,
            });
          });
      };
      checkToken();
      // Establish and interval to refresh the authentication token from the server to prevent expiring during a user's session.
      // Token interval is in seconds, so we multiply by 1000 to set the millisecond value for the setInterval() function.
      refreshTokenInterval = setInterval(() => {
        getRefreshToken();
      }, config.tokenRefreshInterval * 1000);
      activityWatcher();
    }
  };

  /**
   * Attempt to refresh the authentication token using the current token.
   * The user will be logged out if the token is invalid.
   */
  const getRefreshToken = () => {
    if (config.tokenRefreshInterval !== null && config.authenticationType === "default" && getLocalToken("jwt")) {
      console.log(
        "making getRefreshToken request",
        config.services.tokenRefreshService
      );
      authAPI
        .post(config.services.tokenRefreshService)
        .then((response) => {
          storeData("jwt", response.data.jwt);
        })
        .catch((error) => {
          console.log("Problem occurred refreshing token.", error);
          logOut();
        });
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        userError,
        userActivityState,
        handleLogin: handleLogin || null, // This will be set by the AuthProvider when the app mounts
        logOut: logOut || null, // This will be set by the AuthProvider when the app mounts
        getRefreshToken,
        setUser,
        setUserError,
        setUserActivityState,
        validateToken,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
