import { atom } from "recoil";
import { logAtom } from "./../utilities/utilities";

const testDetailsAtom = atom({
  key: " testDetails",
  default: null,
  effects: [logAtom("testDetailsAtom")],
});

export default testDetailsAtom;
