import React from "react";
import { Table } from "semantic-ui-react";

const SpecimenTable = (props) => {
  const data = props.data || [];
  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Laboratory</Table.HeaderCell>
            <Table.HeaderCell>Container Type</Table.HeaderCell>
            <Table.HeaderCell>Default</Table.HeaderCell>
            <Table.HeaderCell>Stability</Table.HeaderCell>
            <Table.HeaderCell>Min. Vol.</Table.HeaderCell>
            {/* <Table.HeaderCell>TAT (minutes)</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((item, key) => (
            <Table.Row key={`specimen-${key}`}>
              <Table.Cell>{item.AuthorizedLab}</Table.Cell>
              <Table.Cell>{item.ContainerType}</Table.Cell>
              <Table.Cell>{item.DefaultContainerType}</Table.Cell>
              <Table.Cell>{item.Temperature}</Table.Cell>
              <Table.Cell>{item.Volume}</Table.Cell>
              {/* <Table.Cell>{item.TurnaroundTimeMinutes}</Table.Cell> */}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default SpecimenTable;
