import React, { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../context/user-context";
import { Message } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import { tokenExists } from "../utilities/authentication";
import config from "../config";

const required = (value) => (value ? undefined : "Required");

const LogInForm = (props) => {
  const userContext = useContext(UserContext);
  const usernameInput = useRef(null);
  const [serverErrors, setServerErrors] = useState({
    errors: false,
    errorMsg: "",
  });

  const isValidatingToken =
    tokenExists() && !userContext.user.isLoggedIn ? true : false;

  useEffect(() => {
    if (usernameInput && usernameInput.current) usernameInput.current.focus();
  }, []);

  const onSubmit = async (values) => {
    setServerErrors({
      errors: false,
      errorMsg: "",
    });
    userContext.handleLogin(values.name, values.password);
  };

  return (
    <>
      {isValidatingToken ? (
        <Message
          color={`${
            window.localStorage.getItem(`${config.appStorageKey}_THEME`) ===
            "dark"
              ? "black"
              : "yellow"
          }`}
          header="Please wait"
          content="Verifying authentication token..."
        />
      ) : (
        <>
          {serverErrors.errors && (
            <Message
              error
              header="There was a problem"
              content={serverErrors.errorMsg}
            />
          )}
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form
                onSubmit={handleSubmit}
                className="ui form login-form"
                data-testid="loginform"
              >
                <Field name="name" validate={required}>
                  {({ input, meta }) => (
                    <div
                      className={
                        meta.error && meta.touched ? "error field" : "field"
                      }
                    >
                      <label htmlFor="name">Username</label>
                      <input
                        {...input}
                        id="name"
                        type="text"
                        placeholder="Name"
                        ref={usernameInput}
                      />
                      {meta.error && meta.touched && (
                        <div className="ui pointing above prompt label">
                          {meta.error}
                        </div>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="password" validate={required}>
                  {({ input, meta }) => (
                    <div
                      className={
                        meta.error && meta.touched ? "error field" : "field"
                      }
                    >
                      <label htmlFor="password">Password</label>
                      <input
                        {...input}
                        id="password"
                        type="password"
                        placeholder="Password"
                      />
                      {meta.error && meta.touched && (
                        <div className="ui pointing above prompt label">
                          {meta.error}
                        </div>
                      )}
                    </div>
                  )}
                </Field>

                <div className="buttons">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="ui button primary"
                  >
                    Submit
                  </button>{" "}
                  <button
                    className="ui button"
                    type="button"
                    onClick={() => {
                      form.reset();
                      usernameInput.current.focus();
                    }}
                    disabled={submitting || pristine}
                  >
                    Reset
                  </button>
                </div>
              </form>
            )}
          />
        </>
      )}
    </>
  );
};
export default LogInForm;
