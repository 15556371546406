import React, { useContext, useEffect } from "react";
import { useRecoilState } from "recoil";
import SearchBox from "./search-box";
import searchFiltersAtom from "../recoil/searchFiltersAtom";
import SearchLetters from "./search-letters";
import ApplicationContext from "../context/application-context";

const SearchTests = (props) => {
  const applicationContext = useContext(ApplicationContext);
  const searchBoxRef = props.searchBoxRef;
  const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersAtom);

  /**
   * Clears out the search box and resets the search results in context.
   */
  const clearSearch = () => {
    searchBoxRef.current.value = "";
    searchBoxRef.current.focus();
    setSearchFilters({ ...searchFilters, term: null, loading: false });
    applicationContext.handleSearchChange();
  };

  // We are using the useEffect hook with an empty array to simulate behavior like the componentDidMount lifecycle of
  // React Class based components and setting the focus in the search box.
  useEffect(() => {
    searchBoxRef.current.focus();
  }, []);

  return (
    <div className="test-search-wrapper">
      <h3>Find a Test</h3>
      <label htmlFor="test-search">
        Find a test by name, code, CPT, or keyword
      </label>
      <div className="search-box">
        <div className="column">
          <SearchBox
            id="test-search"
            isLoading={searchFilters.loading}
            placeholder="Search the test catalog..."
            handleChange={applicationContext.handleSearchChange}
            ref={searchBoxRef}
            initialValue={searchFilters.term || ""}
          />
        </div>
        <div className="column">
          {searchFilters.term && (
            <button
              className="link"
              onClick={() => {
                clearSearch();
              }}
              style={{ marginLeft: "1em" }}
            >
              Clear
            </button>
          )}
        </div>
      </div>
      <SearchLetters />
    </div>
  );
};

export default SearchTests;
