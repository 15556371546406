/**
 * SearchBox component to display a table of patient records.
 * React Props:
 *  - @param {string} id Id for the search box input.  This can be used when declaring the "for" attribute of an HTML label.
 *  - @param {boolean} isLoading A function to invoke when a row is clicked.  The function will be passed the patient id.
 *  - @param {string} placeholder Placeholder text to show in the search input element
 *  - @param {function} handleChange Function that will be called every time the search value changes.
 *    The component will provide the value from the search box to the function on change.
 */
import React, { useEffect, useRef } from "react";
const SearchBox = (props, forwardedRef) => {
  const inputId = props.id || "";
  const isLoading = props.isLoading || false;
  const placeholder = props.placeholder || "";
  const handleChange = props.handleChange || function () {};
  const loadStyle = isLoading ? "loading" : "";
  const defaultRef = useRef(null);
  const searchBoxRef = forwardedRef || defaultRef;

  const handleSearchChange = () => {
    const term = searchBoxRef.current.value;
    handleChange(term);
  };

  useEffect(() => {
    if (props.initialValue && props.initialValue.length > 0) {
      searchBoxRef.current.value = props.initialValue;
    }
  }, []);
  return (
    <div className={`ui ${loadStyle} search`}>
      <div className="ui icon input">
        <input
          id={inputId}
          placeholder={placeholder}
          ref={searchBoxRef}
          type="text"
          onChange={handleSearchChange}
          tabIndex="0"
          className="prompt"
          style={{ width: "300px" }}
        />
        <i aria-hidden="true" className={`icon search-icon ${loadStyle}`}></i>
      </div>
    </div>
  );
};

const forwardedSearchBox = React.forwardRef(SearchBox);

export default forwardedSearchBox;
