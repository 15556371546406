import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import config from "../config";
import Loader from "../components/loader";
import TestDetails from "../components/test-details";
import testDetailsAtom from "../recoil/testDetailsAtom";
import { Link, useParams } from "react-router-dom";

const TestDetailsPage = () => {
  const labId = useParams().labId;
  const orderableProcedure = useParams().procedure;
  const testId = useParams().testId;
  const [testDetails, setTestDetails] = useRecoilState(testDetailsAtom);

  const selectedLab = config.labList.find((item) => item.labId === labId);
  useEffect(() => {
    fetch(`${config.services.appAPI}/v1/${labId}/test-details/${testId}`)
      .then((res) => res.json())
      .then((json) => {
        setTestDetails(json);
      });
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="header-content">
          {selectedLab?.displayName}:{" "}
          {testDetails?.Test && testDetails.Test.TestInformation.TestName}
        </div>
      </div>
      <div className="content">
        <Link to={`/${selectedLab.slug}`} className="ui button">
          Back to Test Catalog
        </Link>
        <div className="details-wrapper">
          <div className="details-section">
            {!testDetails?.Test && <Loader />}
            {testDetails?.Test && (
              <TestDetails
                data={testDetails}
                orderableProcedure={orderableProcedure}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default TestDetailsPage;
