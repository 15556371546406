import React from "react";
import { Route, Routes } from "react-router-dom";
import { getApplicationRoutes } from "./../utilities/navigation";

import NotFound from "./404";

/**
 * Exports a Reach Router <PageRouter> component that is responsible for rendering the various pages based on the URL route
 * @module PageRouter
 * @param {Object} props accepts incoming props object as standard React props.
 */
const PageRouter = (props) => (
  <Routes>
    {getApplicationRoutes()}
    <Route path="*" element={<NotFound />}></Route>
  </Routes>
);

export default PageRouter;
