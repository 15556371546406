import React from "react";

import UserContext from "../context/user-context";
import LogInForm from "./../components/login-form";
import { Link } from "react-router-dom";
import config from "./../config";

const LabListing = (props) => {
  const name = props.lab.displayName;
  const slug = props.lab.slug;
  const labId = props.lab.labId;
  const description = props.lab.description || "";
  return (
    <li>
      <a href={`/${slug}`}>{name}</a>
      {description && (
        <>
          <br />
          <span>{description}</span>
        </>
      )}
    </li>
  );
};

const HomePage = () => {
  const splitPosition = Math.ceil(config.labList.length / 2);
  const labs = [...config.labList];
  const column1 = labs.splice(0, splitPosition);
  const column2 = labs.splice(-splitPosition);

  return (
    <>
      <div className="lab-directory-index">
        <ul>
          {column1.map((lab, i) => (
            <LabListing key={`lab-${i}`} lab={lab} />
          ))}
        </ul>
        <ul>
          {" "}
          {column2.map((lab, i) => (
            <LabListing key={`lab-${i}`} lab={lab} />
          ))}
        </ul>
      </div>
    </>
  );
};
export default HomePage;
