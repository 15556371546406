import React, { useContext } from "react";
import PushyNavBar from "./pushy-nav-bar";
import UserContext from "../context/user-context";
import { useNavigate } from "react-router-dom";
import config from "./../config";
import { getMenuItems } from "./../utilities/navigation";
import ThemeContext from "./../context/theme-context";

const logoSrc = require("./../images/logo.png");

const Navbar = (props) => {
  const navigate = useNavigate();
  const themeContext = useContext(ThemeContext);
  const userContext = useContext(UserContext);

  return (
    <PushyNavBar
      buttonClass="ui button"
      homeLink={config.server.siteRoot}
      isLoggedIn={userContext.user.isLoggedIn}
      links={getMenuItems()}
      loggedInMessage={`Welcome, ${userContext.user.displayName}`}
      loggedOutMessage={`Welcome, please log in`}
      showLogin={config.authenticationType === "none" ? false : true}
      handleLogIn={() => {
        if (config.authenticationType === "oauth") {
          userContext.handleLogin();
        } else {
          navigate(`${config.server.siteRoot}login`);
        }
      }}
      handleLogOut={() => {
        userContext.logOut();
        navigate(config.server.siteRoot);
      }}
      logoSrc={logoSrc.default}
      // logoStyles={{ height: "80px" }}
      logoDescription={config.siteMetadata.title}
      showMobileTitleLink={true}
      title={config.siteMetadata.title}
      theme={themeContext.theme}
      toggleTheme={themeContext.toggleTheme}
    />
  );
};

export default Navbar;
