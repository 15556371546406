import { atom } from "recoil";
import { logAtom } from "./../utilities/utilities";

const searchResultsAtom = atom({
  key: "searchResults",
  default: [],
  effects: [logAtom("searchResultsAtom")],
});

export default searchResultsAtom;
