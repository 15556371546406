import React from "react";
import searchFiltersAtom from "../recoil/searchFiltersAtom";
import { useRecoilState } from "recoil";

const ResultsPerPage = () => {
  const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersAtom);

  const changePaginationSize = (evt) => {
    const value = evt.target.value;

    setSearchFilters({ ...searchFilters, paginationSize: value });
  };
  return (
    <>
      {/* Show results per page when greater than minimum pagination size. */}
      {searchFilters.totalResults > 20 && (
        <div
          className="pagination-select-wrapper"
          style={{ textAlign: "right" }}
        >
          <span style={{ paddingRight: "1em" }}>Results per page:</span>
          <select
            id="pagination-select"
            className="ui select"
            value={searchFilters.paginationSize}
            onChange={changePaginationSize}
          >
            <option key="p20" value="20">
              20
            </option>
            <option key="p50" value="50">
              50
            </option>
            <option key="p100" value="100">
              100
            </option>
          </select>
        </div>
      )}
    </>
  );
};

export default ResultsPerPage;
