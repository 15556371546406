import React from "react";
import { Link } from "react-router-dom";
import selectedLabAtom from "../recoil/selectedLabAtom";
import { useRecoilValue } from "recoil";

const TestListItem = (props) => {
  const data = props.test || {};
  const selectedLab = useRecoilValue(selectedLabAtom);
  const labId = selectedLab.labId;
  return (
    <Link
      to={`/test/${labId}/${data.OrderableProcedures}/${data.TestID}`}
      className="link test-list-item"
    >
      <div className="test-list-item-wrapper">
        <h3>{data.TestName}</h3>
        <div className="code-list">
          <div className="code-list-item">
            <div className="label">Orderable Procedure</div>
            <div className="value">{data.OrderableProcedures}</div>
          </div>
          <div className="code-list-item">
            <div className="label">CPT-4 Code(s)</div>
            <div className="value">{data.ChargeableCPTCode}</div>
          </div>

          <div className="code-list-item">
            <div className="label">Performed At</div>
            {data.Method}
            {/* {[...new Set(data.Method.split(","))].join(", ")} */}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TestListItem;
