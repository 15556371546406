import React, { useEffect } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import searchFiltersAtom from "../recoil/searchFiltersAtom";
const LetterButton = (props) => {
  const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersAtom);
  let searchValue;
  switch (props.letter) {
    case "all":
      searchValue = null;
      break;

    case "0-9":
      searchValue = "number";
      break;

    default:
      searchValue = props.letter.toLowerCase();
  }
  const setAlpha = (value) => {
    if (value !== null) {
      if (searchFilters.alpha === value) {
        // When a user re-selects the same letter, toggle the filter off
        setSearchFilters({
          ...searchFilters,
          alpha: null,
          activePage: 1,
        });
      } else {
        setSearchFilters({
          ...searchFilters,
          alpha: value,
          activePage: 1,
        });
      }
    } else {
      // When both the incoming value is null and the search filter is null, do nothing.
      // Otherwise fetch new values.
      if (
        value === null &&
        (searchFilters.alpha !== null || searchFilters.allergens !== null)
      ) {
        setSearchFilters({
          ...searchFilters,
          alpha: null,
          allergens: null,
          activePage: 1,
        });
      }
    }
  };
  return (
    <button
      disabled={searchFilters.loading}
      className={`filter-link ${
        searchValue !== null && searchFilters.alpha === searchValue
          ? "selected"
          : ""
      }`}
      onClick={() => {
        setAlpha(searchValue);
      }}
    >
      {props.letter.toUpperCase()}
    </button>
  );
};
const SearchLetters = (props) => {
  const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersAtom);

  const setAllergens = (value) => {
    // When a user re-selects an Allergen, toggle the filter off
    if (searchFilters.allergens === value) {
      setSearchFilters({ ...searchFilters, allergens: null, activePage: 1 });
    } else {
      setSearchFilters({
        ...searchFilters,
        allergens: value,
        excludeAllergens: false,
        activePage: 1,
      });
    }
  };

  useEffect(() => {
    const filterParams = [];
    searchFilters.alpha && filterParams.push(`alpha=${searchFilters.alpha}`);
    searchFilters.allergens &&
      filterParams.push(`allergens=${searchFilters.allergens}`);
    searchFilters.term && filterParams.push(`term=${searchFilters.term}`);
  }, [searchFilters]);
  return (
    <div className="search-letters-wrapper">
      <ul className="search-letters">
        <li>
          <LetterButton letter="all" />
        </li>
        <li>
          <LetterButton letter="a" />
        </li>
        <li>
          <LetterButton letter="b" />
        </li>
        <li>
          <LetterButton letter="c" />
        </li>
        <li>
          <LetterButton letter="d" />
        </li>
        <li>
          <LetterButton letter="e" />
        </li>
        <li>
          <LetterButton letter="f" />
        </li>
        <li>
          <LetterButton letter="g" />
        </li>
        <li>
          <LetterButton letter="h" />
        </li>
        <li>
          <LetterButton letter="i" />
        </li>
        <li>
          <LetterButton letter="j" />
        </li>
        <li>
          <LetterButton letter="k" />
        </li>
        <li>
          <LetterButton letter="l" />
        </li>
        <li>
          <LetterButton letter="m" />
        </li>
        <li>
          <LetterButton letter="n" />
        </li>
        <li>
          <LetterButton letter="o" />
        </li>
        <li>
          <LetterButton letter="p" />
        </li>
        <li>
          <LetterButton letter="q" />
        </li>
        <li>
          <LetterButton letter="r" />
        </li>
        <li>
          <LetterButton letter="s" />
        </li>
        <li>
          <LetterButton letter="t" />
        </li>
        <li>
          <LetterButton letter="u" />
        </li>
        <li>
          <LetterButton letter="v" />
        </li>
        <li>
          <LetterButton letter="w" />
        </li>
        <li>
          <LetterButton letter="x" />
        </li>
        <li>
          <LetterButton letter="y" />
        </li>
        <li>
          <LetterButton letter="z" />
        </li>
        <li className="numeric">
          <LetterButton letter="0-9" />
        </li>
        <li className="allegens">
          <button
            className={`filter-link ${
              searchFilters.allergens === "1" ? "selected" : ""
            }`}
            onClick={() => {
              setAllergens("1");
            }}
          >
            Allergens
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SearchLetters;
