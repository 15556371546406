import React, { useContext } from "react";
import { useRecoilState } from "recoil";
import ApplicationContext from "../context/application-context";
import searchFiltersAtom from "../recoil/searchFiltersAtom";

const SearchPills = (props) => {
  const applicationContext = useContext(ApplicationContext);
  const searchBoxRef = props.searchBoxRef;
  const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersAtom);
  const alpha = searchFilters.alpha;
  const allergens = searchFilters.allergens;
  const term = searchFilters.term;
  const showFilters =
    searchFilters.term || searchFilters.alpha || searchFilters.allergens;
  return (
    <>
      {/* Show alpha letter pill display */}
      {showFilters && (
        <div className="search-pills">
          Filters:{" "}
          {alpha && (
            <span>
              <button
                aria-label={`Remove filter for ${alpha}`}
                title={`Remove filter ${alpha}`}
                className="pill"
                id="close-alpha"
                onClick={() => {
                  setSearchFilters({
                    ...searchFilters,
                    alpha: null,
                    loading: true,
                    activePage: 1,
                  });
                }}
              >
                {alpha === "number" ? "0-9" : alpha}{" "}
                <span className="close">x</span>
              </button>
            </span>
          )}
          {/* Show term pill display */}
          {term && (
            <span>
              <button
                aria-label={`Remove filter ${term}`}
                title={`Remove filter for ${term}`}
                className="pill"
                id="close-term"
                onClick={() => {
                  setSearchFilters({
                    ...searchFilters,
                    term: null,
                    loading: true,
                  });
                  if (searchBoxRef) {
                    searchBoxRef.current.value = "";
                    applicationContext.handleSearchChange();
                  } else {
                    console.warn(
                      `Please provide a "searchBoxRef" prop with a React Ref for the search box to clear when removing the term pill.`
                    );
                  }
                }}
              >
                {term} <span className="close">x</span>
              </button>
            </span>
          )}
          {/* Show allergen pill display */}
          {allergens && (
            <span>
              <button
                aria-label="Remove filter for Allergens"
                title="Remove filter Allergens"
                className="pill"
                id="close-allergens"
                onClick={() => {
                  setSearchFilters({
                    ...searchFilters,
                    allergens: null,
                    loading: true,
                  });
                }}
              >
                Allergens <span className="close">x</span>
              </button>
            </span>
          )}
          {/* Show exclude allergens display */}
          {(searchFilters.alpha || searchFilters.term) &&
            !searchFilters.allergens && (
              <span>
                <input
                  id="exclude-allergens"
                  type="checkbox"
                  className="ui checkbox"
                  style={{ marginLeft: "1em", marginRight: ".5em" }}
                  checked={searchFilters.excludeAllergens}
                  onChange={(evt) => {
                    if (evt.currentTarget.checked) {
                      setSearchFilters({
                        ...searchFilters,
                        allergens: null,
                        excludeAllergens: true,
                        loading: true,
                      });
                    } else {
                      setSearchFilters({
                        ...searchFilters,
                        excludeAllergens: false,
                        loading: true,
                      });
                    }
                  }}
                />
                <label
                  htmlFor="exclude-allergens"
                  style={{ cursor: "pointer" }}
                >
                  Exclude Allergens
                </label>
              </span>
            )}
        </div>
      )}
    </>
  );
};

export default SearchPills;
