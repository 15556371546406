import React, { useContext } from "react";
import UserContext from "../context/user-context";
import { userHasAccess } from "../utilities/authentication";
import LoginPage from "../pages/login";
import NotAuthorizedPage from "../pages/not-authorized";
import config from "./../config";

/**
 * PrivateRoute accepts a few react props and will apply permissions to a given route matched against the user's roles.
 * @param {Component} as React component to display when the route matches.
 * @param {String} path URL path to match
 * @param {Array} roles An array of roles that are allowed to view the route.
 */
const PrivateRoute = (props) => {
  const userContext = useContext(UserContext);
  const isLoggedIn = userContext.user.isLoggedIn;
  const isRestricted = props.roles || [];
  const allowed =
    isRestricted.length === 0 ? true : userHasAccess(isRestricted);

  let { as: Comp } = props;
  if (!isLoggedIn && config.authenticationType === "oauth") {
    userContext.handleLogin();
    return <NotAuthorizedPage />;
  } else if (!isLoggedIn) {
    return <LoginPage />;
  } else if (allowed === true) {
    return <Comp {...props} />;
  } else {
    return <NotAuthorizedPage />;
  }
};

export default PrivateRoute;
