import React, { useContext, useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import UserContext from "./../context/user-context";

let timer = null; // This will be assigned the instance of the setInterval() so it can be programatically cleared.

/**
 * The indicator displays a modal box with a countdown when the user has been inactive for a duration of time
 * as indcated by the userActivityState from UserContext.
 * @param {Number} props.initialCountdownTime - Amount of time to display in the countdown timer before automatically logging the user out from inactivity.
 * @returns InactiveIndicator React component
 */
const InactiveIndicator = (props) => {
  const userContext = useContext(UserContext);
  const initialCountdownTime = props.countDownTime || 120;
  let countdown = initialCountdownTime;
  const [timeLeft, setTimeLeft] = useState(initialCountdownTime);

  function startTimer() {
    setTimeLeft(initialCountdownTime);
    countdown = initialCountdownTime;

    timer = setInterval(() => {
      countdown--;
      setTimeLeft(countdown);
      if (userContext.userActivityState === "inactive" && countdown === 0) {
        clearTimeout(timer);
        userContext.logOut();
      }
    }, 1000);
  }
  useEffect(() => {
    if (
      userContext.userActivityState === "inactive" &&
      userContext.user.isLoggedIn
    ) {
      startTimer();
    } else {
      clearTimeout(timer);
    }
  }, [userContext.userActivityState]);
  return (
    <Modal
      className="inactive-indicator"
      open={userContext.userActivityState === "inactive"}
    >
      {/* <Modal.Header>Are you still there?</Modal.Header> */}
      <div className="activity-header">Are you still there?</div>
      <p style={{ padding: "3em 1.5em" }}>
        You will be signed out in {timeLeft} seconds.
      </p>
    </Modal>
  );
};

export default InactiveIndicator;
