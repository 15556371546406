import React, { createRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import config from "../config";

/**
 * Functional component designed to provide responsive generic navbar that is framework independent.  The nav bar currently supports up to a two-level menu.
 * @param {object} props
 *
 */
const PushyNavBar = (props) => {
  // Override any default values with supplied props.
  const buttonClass = props.buttonClass || "button"; // String of CSS classes to apply to buttons in the navbar
  const homeLink = props.homeLink || "/"; // Link to the home page of your application
  const title = props.title || null; // Application or site title to display next to the logo.
  const links = props.links || []; // Array of link objects to display. Example: [{ to: "/home", text: "Home Page" }, { to: "/app/reports", text: "View Reports" }]
  const loggedInMessage = props.loggedInMessage || ""; // Message to display when the user is logged out.
  const loggedOutMessage = props.loggedOutMessage || ""; // Message to display when the user is logged in.
  const loginButtonText = props.loginButtonText || "Login"; // Text of the login button.
  const logoutButtonText = props.logoutButtonText || "Logout"; // Text of the logout button.
  const logoSrc = props.logoSrc || ""; // Logo image URL or Base64 encoded image.
  const logoDescription = props.logoDescription || ""; // Description of logo used to provide alternative text for accessibility.
  const logoStyles = props.logoStyles || {}; // React style object of CSS styles to apply to the logo.
  const isLoggedIn = props.isLoggedIn || false; // Boolean indicating the login state of the user.
  const showLogin = props.showLogin || false;
  const showMobileTitleLink = props.showMobileTitleLink || false; // Displays a title link to the root page of the app in the mobile menu.
  const theme = props.theme || "default";

  const toggleTheme =
    props.toggleTheme ||
    function () {
      console.warn(
        "PushyNavBar -> No toggleTheme function was passed to the component"
      );
    };
  // Function to execute when the Login button is clicked.
  const logIn =
    props.handleLogIn ||
    function () {
      console.warn(
        "PushyNavBar -> No logIn function was passed to the component"
      );
    };
  // Function to execute when the LogOut button is clicked.
  const logOut =
    props.handleLogOut ||
    function () {
      console.warn(
        "PushyNavBar -> No logOut function was passed to the component"
      );
    };

  // Manage state of the menu
  const [open, setOpen] = useState(false);
  const [subOpen, setSubOpen] = useState();
  const menuState = open ? "open" : "";
  const loginOutMessage = isLoggedIn ? loggedInMessage : loggedOutMessage;
  const refCloseButton = createRef();
  const refToggleButton = createRef();
  const parentRefs = [];
  const location = useLocation();

  // Setup a global listener for route changes that will close any open menus
  // when a user navigates to a new page.
  useEffect(() => {
    parentRefs.forEach((parent) => {
      if (
        parent &&
        parent.current &&
        parent.current.className.includes("expanded")
      ) {
        setSubOpen(null);
      }
    });
  }, [location]);

  // Generate a title link if a title has been passed to the pushy navbar.
  const TitleLink = () => {
    return typeof title === "string" ? (
      <span className="title">
        <Link
          to={homeLink}
          onClick={() => {
            close();
          }}
        >
          {title}
        </Link>
      </span>
    ) : (
      <></>
    );
  };

  /**
   * Utility function to that is used to generate links with or without children.
   * @param {object} link Link object used to create the link. e.g. { to: "/home", text: "Home Page" }
   * @param {} key key used by React for diff tracking
   */
  const makeLink = (link, key) => {
    const hasChildren = link.children && link.children.length > 0;
    const css = hasChildren ? "has-children" : "";
    if (hasChildren) {
      let parentRef = createRef();
      const isOpen = subOpen === link.text ? "expanded" : "";
      parentRefs.push(parentRef);
      return (
        <li
          key={`menu-item${key}`}
          className={`${css} ${isOpen}`}
          ref={parentRef}
        >
          <button
            aria-haspopup="true"
            onClick={() => {
              if (parentRef.current.className.includes("expanded")) {
                setSubOpen(null);
              } else {
                setSubOpen(link.text);
              }
            }}
          >
            {link.text}
            <span></span>
          </button>
          {hasChildren && (
            <ul aria-hidden="true" aria-label="submenu" className="submenu ">
              {link.children.map((child, i) =>
                makeLink(child, `menu-item${key}-child-${i}`)
              )}
            </ul>
          )}
        </li>
      );
    } else if (link.path && link.text) {
      return (
        <li key={`menu-item${key}`} className={css}>
          <Link
            aria-haspopup="false"
            to={link.path}
            onClick={() => {
              close();
            }}
            {...link.props}
          >
            {link.text}
          </Link>
        </li>
      );
    } else {
      return null;
    }
  };

  /**
   *   Close the menu
   */
  const close = () => {
    refToggleButton.current.focus();
    setOpen(false);
  };

  /**
   * Toggle the menu open or close
   */

  const toggle = () => {
    let menuState = !open;
    setOpen(menuState);

    if (menuState === true) {
      // Menu is open, set focus on the close button
      refCloseButton.current.focus();
    } else {
      // Menu is closed, set focus on the menu toggle button
      refToggleButton.current.focus();
    }
  };

  return (
    <>
      <div className={`pushy-navbar ${menuState} ${theme}`}>
        <div className="cover" onClick={close} />
        <div className="pushy-main-grid">
          <div className="brand-section">
            <div className="identity">
              <span className="logo">
                <Link to={homeLink}>
                  <img src={logoSrc} alt={logoDescription} style={logoStyles} />
                </Link>
              </span>
              <TitleLink />
            </div>
          </div>
          <div className="push-menu">
            <nav role="navigation">
              <div className="close-container">
                <button
                  aria-label="Close Menu"
                  className={`close`}
                  tabIndex="0"
                  onClick={close}
                  ref={refCloseButton}
                />
                {showMobileTitleLink && <TitleLink />}
              </div>
              <div className="primary-links">
                <ul>{links.map((link, key) => makeLink(link, key))}</ul>
              </div>
              <>
                {config.enableDarkMode && (
                  <div className="theme-selector">
                    <div className="ui toggle checkbox">
                      <input
                        type="checkbox"
                        label="Dark Mode"
                        name="theme-selector"
                        id="pushy-theme-selector-toggle"
                        defaultChecked={
                          window.localStorage.getItem(
                            `${config.appStorageKey}_THEME`
                          ) === "dark"
                            ? true
                            : false
                        }
                        onChange={toggleTheme}
                      />
                      <label
                        htmlFor="pushy-theme-selector-toggle"
                        style={{ cursor: "pointer" }}
                      >
                        Dark Mode
                      </label>
                    </div>
                  </div>
                )}
              </>
              {showLogin && (
                <>
                  <div className="login-logout">
                    <div className="message">{loginOutMessage}</div>
                    {isLoggedIn ? (
                      <button
                        onClick={() => {
                          logOut();
                          close();
                        }}
                        className={`${buttonClass}`}
                      >
                        {logoutButtonText}
                      </button>
                    ) : (
                      <button
                        className={`${buttonClass}`}
                        aria-label="Close Menu"
                        onClick={() => {
                          close();
                          logIn();
                        }}
                      >
                        {loginButtonText}
                      </button>
                    )}
                  </div>
                </>
              )}
            </nav>
          </div>
        </div>
        <button
          className="pushy-navbar-toggle"
          aria-label="Toggle Navigation Menu"
          tabIndex="1"
          onClick={toggle}
          ref={refToggleButton}
        >
          <span />
          <span />
          <span />
        </button>
      </div>
    </>
  );
};

export default PushyNavBar;
