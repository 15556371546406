import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import MethodologyTable from "./methodology-table";
import SpecimenTable from "./specimen-table";

const TestDetails = (props) => {
  const data = props.data;
  const [showMethodology, setShowMethodology] = useState(false);
  const [showSpecimenReqs, setShowSpecimenReqs] = useState(false);

  return (
    <>
      <div className="test-details-wrapper">
        <div className="details-header">
          <div>
            <div className="label">Test</div>
            <div className="value">{data.Test.TestInformation.TestName}</div>
          </div>
          <div>
            <div className="label">Orderable Procedure</div>
            <div className="value">{props.orderableProcedure}</div>
          </div>
          <div>
            <div className="label">CPT-4 Code(s)</div>
            <div className="value">{data.Test.TestInformation.CPTCodes}</div>
          </div>
        </div>
        <div className="details-content">
          <h2>Test Information</h2>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width="8">
                <h3>Synonyms</h3>
                <div>{data.Test.TestInformation.Synonyms.join(", ")}</div>
              </Grid.Column>
              <Grid.Column width="8">
                <h3>Orderable Procedure</h3>
                <div>{props.orderableProcedure}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="16">
                <h3>Collection Requirements</h3>
                <div>{data.Test.TestInformation.CollectionRequirements}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="16">
                <h3>Shipping and Handling Instructions</h3>
                <div>
                  {data.Test.TestInformation.ShippingandHandlingInstructions}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="toggle-section-header">
            <button
              className="ui icon left labeled button mini"
              aria-label="Show Specimen Requirements"
              onClick={() => {
                setShowSpecimenReqs(!showSpecimenReqs);
              }}
            >
              <i
                aria-hidden="true"
                className={`${
                  showSpecimenReqs === true ? "minus" : "plus"
                } icon`}
              ></i>
              {showSpecimenReqs === true ? "Hide" : "Show"}
            </button>
            <h2
              onClick={() => {
                setShowSpecimenReqs(!showSpecimenReqs);
              }}
            >
              Specimen Requirements / Handling / Stability{" "}
            </h2>
          </div>
          {showSpecimenReqs && <SpecimenTable data={data.Test.Labs} />}
          <div className="toggle-section-header">
            <button
              className="ui icon left labeled button mini"
              aria-label="Show Methodology / Reference Range"
              onClick={() => {
                setShowMethodology(!showMethodology);
              }}
            >
              <i
                aria-hidden="true"
                className={`${
                  showMethodology === true ? "minus" : "plus"
                } icon`}
              ></i>
              {showMethodology === true ? "Hide" : "Show"}
            </button>
            <h2
              onClick={() => {
                setShowMethodology(!showMethodology);
              }}
            >
              Methodology / Reference Range{" "}
            </h2>
          </div>
          {showMethodology && (
            <MethodologyTable data={data.Test.MethodologyReferenceRange} />
          )}
        </div>
      </div>
    </>
  );
};

export default TestDetails;
