import React, { useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import fetchResults from "../services/fetchResults";
import Loader from "../components/loader";
import searchFiltersAtom from "../recoil/searchFiltersAtom";
import SearchPills from "../components/search-pills";
import SearchResults from "../components/search-results";
import searchResultsAtom from "../recoil/searchResultsAtom";
import SearchTests from "../components/search-tests";
import selectedLabAtom from "../recoil/selectedLabAtom";
import selectedTestId from "./../recoil/selectedTestIdAtom";
import testDetailsAtom from "./../recoil/testDetailsAtom";
import config from "./../config";
import { useParams } from "react-router-dom";

const TestResultsPage = (props) => {
  const lab = useParams().lab;

  const searchBoxRef = useRef();
  const selectedLab = config.labList.find((item) => item.slug === lab);
  const setTestDetails = useSetRecoilState(testDetailsAtom);
  const setSelectedTestId = useSetRecoilState(selectedTestId);
  const setSelectedLab = useSetRecoilState(selectedLabAtom);
  const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersAtom);
  const [searchResults, setSearchResults] = useRecoilState(searchResultsAtom);
  const labId = selectedLab?.labId;

  useEffect(() => {
    setSelectedLab(selectedLab);
    setSelectedTestId(null);
    setTestDetails(null);
  }, []);

  // When specified fitlers change, send request for new results.
  // Additional fetching occurs when a user types in the searchbox (see /components/searc-tests.js)
  useEffect(() => {
    if (labId) {
      fetchResults(labId, searchFilters, setSearchResults, setSearchFilters);
    }
  }, [
    labId,
    searchFilters.alpha,
    searchFilters.allergens,
    searchFilters.excludeAllergens,
    searchFilters.paginationSize,
    searchFilters.activePage,
  ]);

  return (
    <>
      <div className="page-header">
        <div className="header-content">
          {selectedLab && selectedLab.displayName}
        </div>
      </div>
      <div className="content">
        <SearchTests searchBoxRef={searchBoxRef} />
        <SearchPills searchBoxRef={searchBoxRef} />

        {searchFilters.loading && <Loader />}
        {!searchFilters.loading && <SearchResults data={searchResults} />}
      </div>
    </>
  );
};
export default TestResultsPage;
