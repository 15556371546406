import React, { createContext, useState } from "react";
import config from "../config";

// Define a React Context to store application data.
const ThemeContext = createContext();
ThemeContext.displayName = "ThemeContext";
export default ThemeContext;

export const ThemeProvider = (props) => {
  const [theme, setTheme] = useState("default");
  const modalClass = theme === "dark" ? "theme-dark" : "";
  const paginationClass = theme === "dark" ? "inverted" : "";
  const tableClass = theme === "dark" ? "inverted" : "";
  const tabsClass = theme === "dark" ? "inverted" : "";
  const toggleTheme = () => {
    if (
      window.localStorage.getItem(`${config.appStorageKey}_THEME`) === "dark"
    ) {
      window.localStorage.setItem(`${config.appStorageKey}_THEME`, "default");
    } else {
      window.localStorage.setItem(`${config.appStorageKey}_THEME`, "dark");
    }
    setTheme(window.localStorage.getItem(`${config.appStorageKey}_THEME`));
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        modalClass,
        paginationClass,
        tableClass,
        tabsClass,
        setTheme,
        toggleTheme,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};
