// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    // clientId: "245e9392-c666-4d51-8f8a-bfd9e55b2456",
    clientId: "f71bc5c0-e786-4a04-9146-fd32f9815c06",
    authority: "https://login.microsoftonline.com/common",
    validateAuthority: true,
    postLogoutRedirectUri: "http://localhost:3002",
    // redirectUri: window.location.href,
    redirectUri: "http://localhost:3002",
    navigateToLoginRequestUrl: false,
    response_type: "code",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/messages",
};

// Add here scopes for access token to be used at MS Graph API endpoints.
export const tokenRequest = {
  scopes: ["User.Read"],
};
