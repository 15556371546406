import React from "react";

/**
 *
 * @param {object} props Provides two options to pass to the loader.
 * @param {string} props.message the loading message to display underneath the spinner.  The default value is "Loading ..."
 * @param {string} props.align Accepts "center" or "left".  Default position is "center"
 * 
 * Example
 ``` ``````
 */
const Loader = props => {
  const message = props.message || "Loading ...";
  const align = props.align || "center";
  const spinnerAlign = align === "center" ? "centered" : "";

  return (
    <>
      <div className={`ui active inline loader ${spinnerAlign}`} />
      <div style={{ textAlign: align }}>{message}</div>
    </>
  );
};

export default Loader;
