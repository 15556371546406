import axios from "axios";
import { getAuthHeaders } from "../utilities/authentication";

const axiosConfig = {
  // timeout: 120000,
  // timeout: 1,
  headers: {
    // "X-API-VERSION": "v1",
  },
};

const appAPI = axios.create(axiosConfig);
const authAPI = axios.create(axiosConfig);
const rolesAPI = axios.create(axiosConfig);

// Add a request interceptor to grab the latest token on each API request
// and inject it into the header of the request.
appAPI.interceptors.request.use(function (config) {
  config.headers = getAuthHeaders();
  return config;
});

// Add a request interceptor to grab the latest token on each authentication
// server request and inject it into the header of the request.
authAPI.interceptors.request.use(function (config) {
  config.headers = getAuthHeaders();
  return config;
});

// Used to retrieve roles, provided by the starter app.
rolesAPI.interceptors.request.use(function (config) {
  config.headers = getAuthHeaders();
  return config;
});

export { appAPI, authAPI, rolesAPI };
