import React from "react";
import { Pagination } from "semantic-ui-react";
import TestListItem from "./test-list-item";
import { useRecoilState } from "recoil";
import searchFiltersAtom from "../recoil/searchFiltersAtom";
import ResultsPerPage from "./results-per-page";
import TotalResults from "./total-results";

const SearchResults = (props) => {
  const tests = props.data || [];
  const [searchFilters, setSearchFilter] = useRecoilState(searchFiltersAtom);
  const totalPages = Math.ceil(
    searchFilters.totalResults / searchFilters.paginationSize
  );
  const handlePagination = (evt) => {
    const page = evt.target.getAttribute("value");
    setSearchFilter({ ...searchFilters, activePage: page });
  };

  return (
    <>
      <div className="results-info">
        <TotalResults />
        <ResultsPerPage />
      </div>
      <div style={{ marginBottom: ".5em", textAlign: "center" }}>
        {totalPages > 1 && (
          <Pagination
            activePage={searchFilters.activePage}
            totalPages={totalPages}
            onPageChange={handlePagination}
          />
        )}
      </div>
      {tests.length === 0 && <div>No test found matching your search.</div>}
      {tests.map((item, i) => (
        <TestListItem test={item} key={`test-${i}`} />
      ))}
      <div style={{ marginTop: ".5em", textAlign: "center" }}>
        {totalPages > 1 && (
          <Pagination
            activePage={searchFilters.activePage}
            totalPages={totalPages}
            onPageChange={handlePagination}
          />
        )}
      </div>
    </>
  );
};

export default SearchResults;
