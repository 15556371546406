import React from "react";
import { Table } from "semantic-ui-react";

const MethodologyTable = (props) => {
  const data = props.data || {};
  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Method</Table.HeaderCell>
            <Table.HeaderCell>Name (LOINC)</Table.HeaderCell>
            <Table.HeaderCell>Age</Table.HeaderCell>
            <Table.HeaderCell>Sex</Table.HeaderCell>
            <Table.HeaderCell>Reference Range</Table.HeaderCell>
            <Table.HeaderCell>Units</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.PerformingLabs.map((item, key) => (
            <React.Fragment key={`performing-labs-${key}`}>
              <Table.Row key={`performing-labs-${key}`}>
                <Table.Cell colSpan="5">{item.PerformingLabName}</Table.Cell>
              </Table.Row>
              {item.ReferenceRanges &&
                item.ReferenceRanges.map((range, i) => (
                  <Table.Row key={`range-${item.PerformingLabName}-${i}`}>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell>
                      {" "}
                      {range.ExternalName} ({range.LOINC})
                    </Table.Cell>

                    <Table.Cell>
                      {(range.AgeFrom || range.AgeTo) && (
                        <>
                          {range.AgeFrom} {range.AgeUnitsFrom} - {range.AgeTo}{" "}
                          {range.AgeUnitsTo}{" "}
                        </>
                      )}{" "}
                    </Table.Cell>
                    <Table.Cell>{range.Sex}</Table.Cell>
                    <Table.Cell>
                      {range.NumericRange.length > 0
                        ? range.NumericRange
                        : range.Values_}
                    </Table.Cell>
                    <Table.Cell>{range.Units}</Table.Cell>
                  </Table.Row>
                ))}
            </React.Fragment>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default MethodologyTable;
