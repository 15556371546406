/**
 * The following `window.app.env` variables are defined within the env.js file found in the public folder.
 * The design decision allows for the exact same build to be performed across any environment and the host server
 * will provide the env.js file to set the runtime configuration variable values.
 *
 * Any server changes for development should be made in /public/env.js
 */
let AUTHENTICATION_SERVER;
let APP_API_SERVER = window.app.env.API_URL;
let LAB_LIST = window.app.env.labDefaults;

// When running `yarn mock` from the command line, the NODE_ENV variable is set to "test"
// and local environment will route requests to the mock server.
// NOTE: You must start the mock server by running `yarn mockserver` from the command line.
if (process.env.NODE_ENV === "test") {
  AUTHENTICATION_SERVER = "http://localhost:3456";
  APP_API_SERVER = "http://localhost:3456/v1";
} else {
  AUTHENTICATION_SERVER = window.app.env.AUTH_URL;
  APP_API_SERVER = window.app.env.API_URL;
}

/**
 * The config object below is referenced throughout the application to resolve common properties and server paths in a central location.
 */
const config = {
  debugMode: false, // Boolean. When `true` application context information will be logged to the console.  You can also turn this on via the console by running `window.debugMode = true`.
  authenticationType: "none", // "oauth", "default", or "none". Default uses a custom Active Directory authentication method.  OAuth uses Microsoft Authentication Library (MSAL). None does not display login/logout information.
  siteMetadata: {
    title: "Lab Directory", // Title that appears in the navigation bar.
    description: "",
    author: `Lance Guldi`,
  },
  useRoles: false,
  // roles: [{ label: "Administrator", value: "administrator" }], // Optional mapping of label for roles. This is handy to provide labels for roles when building UI elements.
  server: {
    siteRoot: "/", // Used by the navbar, page configurations, authentication functions, etc. to redirect users to the app root (home page).
  },
  services: {
    loginService: `${AUTHENTICATION_SERVER}/login/`, // URL to send login POST request for user authentication.
    rolesService: `${APP_API_SERVER}/userroles`,
    tokenRefreshService: `${AUTHENTICATION_SERVER}/refresh`, // URL endpoint to refresh an existing token before expiration.
    tokenValidationService: `${AUTHENTICATION_SERVER}/validate/`, // URL of endpoint to validate JWT tokens.
    appAPI: `${APP_API_SERVER}`, // API server URL, in this demo app this is the URL to the blog post requests used in the Blog Example.
  },
  enableDarkMode: false, // Enable theme selector to toggle between "default" or "dark" themes.
  tokenRefreshInterval: 30 * 60, // How often to refresh the token in seconds. Default: 30 * 60 (30 minutes). Set to `null` to disable.
  inactivityTimeout: 30 * 60, // How long before we display the inactivity indicator countdown in seconds. Default: 30 * 60 seconds (30 minutes)
  appStorageKey: "labDirectory", // String name of the user object stored in the session storage.

  labList: LAB_LIST || "",
};

export default config;
