import { atom } from "recoil";
import { logAtom } from "./../utilities/utilities";
const searchFilterDefaults = {
  term: null,
  alpha: null,
  allergens: null,
  excludeAllergens: false,
  loading: false,
  paginationSize: 20,
  activePage: 1,
  totalResults: 0,
};

const searchFiltersAtom = atom({
  key: "searchFilters",
  default: searchFilterDefaults,
  effects: [logAtom("searchFiltersAtom")],
});

export default searchFiltersAtom;
export { searchFilterDefaults };
