import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Message } from "semantic-ui-react";
import ApplicationContext from "./context/application-context";
import AuthProvider from "./msal/AuthProvider";
import config from "./config";
import ErrorBoundary from "./components/error-boundary";
import InactiveIndicator from "./components/inactive-indicator";
import Loader from "./components/loader";
import LogInForm from "./components/login-form";
import Navbar from "./components/navbar";
import PageRouter from "./pages/page-router";
import UserContext from "./context/user-context";
import ThemeContext from "./context/theme-context";

const Application = (props) => {
  /**
   * The problem we are trying to solve with the code below is when a user has a local token in their session storage,
   * we need to verify that token is valid before rendering the navigation (with or without private routes) and before navigating
   * to an endpoint that requires authentication.
   *
   * When using OAuth, we are relying on Microsoft's MSAL library to perform token validation by wrapping our application with an AuthProvider.
   * When using our custom AD authentication, we are importing a utility function from our auth service to perform token validation.
   */

  const applicationContext = useContext(ApplicationContext);
  const themeContext = useContext(ThemeContext);
  const userContext = useContext(UserContext);

  // When using OAuth, we wrap our Application component with a Microsoft Authentication Library (MSAL) AuthProvider Higher Order Component to get authentication functions,
  // then assign the login/logout functions provided by the MSAL instance to the replace the default User Context functions.
  if (config.authenticationType === "oauth") {
    userContext.handleLogin = props.onSignIn;
    userContext.logOut = props.onSignOut;
  }

  // Since browsers will sometimes halt JavaScript execution when the tab is not in focus
  // or the PC has entered lock or sleep mode, we are attempting to refresh the token when a user
  // resumes the application. If the current token is not valid, the user will be logged out.
  useEffect(() => {
    if (config.authenticationType === "default" && window) {
      window.document.addEventListener("focus", () => {
        userContext.getRefreshToken();
      });
    }
  }, []);

  // To handle multiple authentication options, we are setting a effectDependencies variable to monitor changes to props.account when
  // using OAuth via MSAL and userContext when using our custom AD authentication.  The useEffect hook will re-run any time the appropriate dependency changes.
  const effectDependencies =
    config.authenticationType === "oauth" ? props.account : userContext;

  useEffect(() => {
    if (props.account) {
      userContext.setUser({
        ...props.account,
        user: {
          user: props.account,
          name: props.account.name,
          displayName: props.account.name,
          mail: props.account.userName,
        },
        displayName: props.account.name,
        isLoggedIn: true,
      });
    } else {
      userContext.validateToken();
    }
  }, [effectDependencies]);

  // Check to see if the user has set the theme in local storage and if so, apply the theme.
  useEffect(() => {
    if (
      window.localStorage.getItem(`${config.appStorageKey}_THEME`) === "dark"
    ) {
      themeContext.setTheme("dark");
    }
  }, []);
  // We are using a ternary operator to return an error when present, otherwise we return the page.
  return (
    <div className={`theme-${themeContext.theme}`}>
      <Router>
        {userContext.userError && userContext.userError.error ? (
          <>
            <ErrorBoundary>
              <Navbar />
            </ErrorBoundary>
            <div className="main" role="main">
              <ErrorBoundary>
                <Message negative>
                  <Message.Header>Error</Message.Header>
                  <p>
                    {userContext.userError.title}:{" "}
                    {userContext.userError.message}
                  </p>
                </Message>
                <LogInForm />
              </ErrorBoundary>
            </div>
          </>
        ) : (
          <>
            <ErrorBoundary>
              <Navbar />
            </ErrorBoundary>
            <div className="main" role="main">
              <InactiveIndicator countDownTime={120} />
              <ErrorBoundary>
                {applicationContext.isReady ? <PageRouter /> : <Loader />}
              </ErrorBoundary>
            </div>
          </>
        )}
      </Router>
    </div>
  );
};

let App;
if (config.authenticationType === "oauth") {
  // App is exported using MSAL for OAuth authentication
  App = AuthProvider(Application);
} else {
  // App is exported using a custom Active Directory authentication
  App = Application;
}
export default App;
