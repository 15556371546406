import { atom } from "recoil";
import { logAtom } from "./../utilities/utilities";

const selectedLabAtom = atom({
  key: " selectedLab",
  default: null, // lab object from the labsAtom. Example: {id: 1234, displayName: "Location 1234", name: "lab-name"}
  effects: [logAtom("selectedLabAtom")],
});

export default selectedLabAtom;
