import { atom } from "recoil";
import { logAtom } from "./../utilities/utilities";

const selectedTestIdAtom = atom({
  key: " selectedTestId",
  default: null, // id of the selected test.
  effects: [logAtom("selectedTestIdAtom")],
});

export default selectedTestIdAtom;
