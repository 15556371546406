import React from "react";

const NotFound = () => (
  <>
    <h1>404 - NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist...oh the humanity!</p>
  </>
);

export default NotFound;
