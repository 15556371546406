import config from "../config";

const fetchResults = async (
  labid,
  filters,
  setSearchResults,
  setSearchFilters
) => {
  const term = filters.term ? `term=${filters.term}` : "";
  const alpha = filters.alpha ? `alpha=${filters.alpha}` : "";
  const allergens = filters.allergens ? `allergens=${filters.allergens}` : "";
  const activePage = filters.activePage
    ? `pagenumber=${filters.activePage}`
    : "";
  const paginationSize = filters.paginationSize
    ? `resultsize=${filters.paginationSize}`
    : "";
  const excludeAllergens = filters.excludeAllergens ? `allergens=${0}` : "";

  const searchParameters = [];
  [
    term,
    alpha,
    allergens,
    excludeAllergens,
    paginationSize,
    activePage,
  ].forEach((item) => {
    if (item !== "") searchParameters.push(item);
  });

  setSearchFilters({ ...filters, loading: true });

  let response = await fetch(
    `${config.services.appAPI}/v1/${labid}/test-search?${searchParameters.join(
      "&"
    )}`
  )
    .then((response) => response.json())
    .then((json) => {
      if (json.error) {
        return {
          error: json.error,
        };
      } else {
        // Clear out any prior errors
        setSearchResults(json.SearchResultData);
        setSearchFilters({
          ...filters,
          totalResults: json.TotalRows,
          loading: false,
        });
      }
    })
    .catch((err) => {
      const message =
        err.config && err.config.url && err.config.method
          ? `${err.message}. ${err.config.method.toUpperCase()} ${
              err.config.url
            }`
          : err.message;

      return {
        error: message,
      };
    });
  return response;
};

export default fetchResults;
