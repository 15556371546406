import React, { createContext } from "react";
import selectedLabAtom from "./../recoil/selectedLabAtom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { debounce } from "../utilities/utilities";
import searchFiltersAtom from "../recoil/searchFiltersAtom";
import fetchResults from "./../services/fetchResults";
import searchResultsAtom from "../recoil/searchResultsAtom";

const ApplicationContext = createContext();
ApplicationContext.displayName = "ApplicationContext";
export default ApplicationContext;

export const ApplicationProvider = (props) => {
  const [searchFilters, setSearchFilters] = useRecoilState(searchFiltersAtom);
  const setSearchResults = useSetRecoilState(searchResultsAtom);

  const selectedLab = useRecoilValue(selectedLabAtom);
  const labId = selectedLab?.labId;

  /**
   *  We are debouncing the search so we do not query the server until the user has not typed for at least .8 seconds.
   */
  const handleSearchChange = debounce(
    (term) => {
      if (labId) {
        // Only execute after a lab has been resolved.
        if (!term || term.length < 1) {
          setSearchFilters({
            ...searchFilters,
            term: null,
            loading: false,
            activePage: 1,
          });
          fetchResults(
            labId,
            { ...searchFilters, term: null, loading: false, activePage: 1 },
            setSearchResults,
            setSearchFilters
          );
        } else {
          setSearchFilters({
            ...searchFilters,
            term: term,
            loading: true,
            activePage: 1,
          });
          fetchResults(
            labId,
            { ...searchFilters, term: term, loading: true, activePage: 1 },
            setSearchResults,
            setSearchFilters
          );
        }
      }
    },
    800,
    false
  );

  // The isReady context property is used to indicate that all three core application services have retrieved data and set the application context appropriately
  // const isReady = floorsReady && physiciansReady && servicesReady;
  const isReady = true;

  return (
    <ApplicationContext.Provider value={{ handleSearchChange, isReady }}>
      {props.children}
    </ApplicationContext.Provider>
  );
};
