// Import pages and route components
import config from "./../config";

// Default Pages
import HomePage from "./home";
import TestResultsPage from "./test-results-page";
import TestDetailsPage from "./test-details-page";

const pages = [
  {
    menu: false,
    path: "/",
    component: HomePage,
    text: "Home",
  },
  {
    menu: false,
    path: "/:lab",
    component: TestResultsPage,
    text: "Test Results",
  },
  {
    menu: false,
    path: "/test/:labId/:procedure/:testId",
    component: TestDetailsPage,
    text: "Test Details",
  },
];

// Prepend the siteRoot to the paths to handle deployment to a subdirectory.
const ROUTES_PAGES = pages.map((page) => {
  const path =
    page.path?.substring(0, 1) === "/"
      ? config.server.siteRoot + page.path.slice(1)
      : config.server.siteRoot + page.path;
  return { ...page, ...{ path: path } };
});

export default ROUTES_PAGES;
